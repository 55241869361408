<template>
  <div class="tags">
    <van-tabs @click="tabClick"
              color="#1989fa"
              :swipeable="true">
      <van-tab :title="item.text"
               :name="item.value"
               v-for="(item, index) in tagsList"
               :key="index"></van-tab>
    </van-tabs>
    <van-cell-group>
      <van-field placeholder="请输入账号"
                 bind:change="onChange"
                 v-model="account"
                 label="账号"
                 @blur="queryUser"
                 :maxlength="11" />
      <van-field v-model="nickName"
                 label="姓名"
                 readonly />
      <van-field placeholder="请输入充值金额"
                 bind:change="onChange"
                 v-model="money"
                 label="充值金额"
                 v-if="showMoney"
                 @blur="regMoney" />
      <!--时间计算组件-->
      <calcTime label="到期时间"
                :dateValue="memberEndTime"
                @result="calcResult"
                @change="changeMonth" />
      <!--充值通道组件-->
      <payChannel @channelValue="chooseChannel"
                  v-if="showChannel" />
      <!--职业组件-->
      <jobType @jobValue="jobValue"
               v-if="showJob" />
      <!--文件上传组件-->
      <fileUpload :userId="userId"
                  @imageUrl="getUrl"
                  v-if="showImage" />
      <!--律所组件-->
      <lawFirm @chooseLaw="chooseLaw"
               @lawText="changeLawText"
               v-if="isLaw"
               direction="up" />
      <!--选择客服-->
      <customer @cusValue="chooseCus"
                v-if="showCus" />
      <!--销售人-->
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item :value="salesperson"
                           :options="salespersonList"
                           @change="chooseSaler"></van-dropdown-item>
      </van-dropdown-menu>
      <!--选择审批人-->
      <approveUser @approveValue="chooseApprove"
                   v-if="showApprove" />
      <van-field placeholder="请输入申请理由"
                 type="textarea"
                 v-model="remark"
                 label="申请理由"
                 v-if="showRemark" />
    </van-cell-group>
    <div class="footer">
      <van-button type="info"
                  size="large"
                  :square="true"
                  @click="apply"
                  :disabled="dis">申请</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //头部组件内容
      dis: false,
      tagsList: [
        {
          text: '律师开通',
          value: 0,
        },
        {
          text: '非律师开通',
          value: 1,
        },
      ],
      account: '', //账号
      cusValue: 0, //选中的客服信息
      nickName: '', //姓名
      userId: 0,
      jobType: '律师', //用户角色
      isLaw: true, //是否为律师开通
      applyMonth: 0, //充值时间
      money: '', //充值金额
      memberEndTime: '', //会员到期时间
      payChannel: '', //充值渠道
      imageUrl: '', //充值图片
      approveUser: '', //审批人
      lawfirmValue: 0, //律所信息
      remark: '', //申请理由
      memberStartTime: '', //会员开始时间
      lawfirmName: '', //律所名称
      showMoney: true, //展示充值金额
      showChannel: true, //展示充值通道
      showImage: true, //展示充值图片
      showCus: true, //展示客服选择
      showApprove: true, //展示审批人
      showRemark: true, //展示申请理由
      showMonth: '', //时间最终运算结果
      showJob: true, //展示职业
      titleType: '律师开通',
      salesperson: 'none', //销售人
      salespersonList: [],
    }
  },
  methods: {
    //充值金额校验
    regMoney() {
      if (this.money % 1 != 0) {
        this.$toast('充值金额请输入整数')
      }
    },
    //加载销售人
    loadSaler() {
      this.salespersonList.push({
        text: '请选择销售人',
        value: 'none',
      })
      this.$axios
        .get(`${this.$base}/fission/page/queryCustomer`)
        .then((res) => {
          if (res) {
            res.forEach((item) => {
              this.salespersonList.push({
                text: item.name,
                value: item.name,
              })
            })
            this.salespersonList.push({
              text: 'app自助下单',
              value: 'app自助下单',
            })
            this.salespersonList.push({ text: '智远', value: '智远' })
            this.salespersonList.push({ text: '名家讲堂', value: '名家讲堂' })
            this.salespersonList.push({ text: '明法学苑', value: '明法学苑' })
            this.salespersonList.push({ text: '智拾', value: '智拾' })
          }
        })
    },
    //选择销售人
    chooseSaler(value) {
      this.salesperson = value
    },
    //根据手机号查询用户
    queryUser() {
      var myreg = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      if (!myreg.test(this.account) || !this.account) {
        this.nickName = ''
        this.$toast('请输入正确的手机号')
        return
      }
      //查询会员信息
      this.$axios
        .get(`${this.$base}/admin/lb/user?account=${this.account}`)
        .then((res) => {
          if (res.code === 200) {
            this.account = res.data.account
            this.nickName = res.data.nickname
            this.userId = res.data.id
            this.memberStartTime = res.data.memberStartTime
            if (res.data.memberEndTime) {
              var nowDay = this.dayjs().format('YYYY-MM-DD')

              var resDiff = this.dayjs(res.data.memberEndTime).diff(
                this.dayjs(),
                'days'
              )
              if (resDiff < 0) {
                this.memberEndTime = nowDay
              } else {
                this.memberEndTime = this.dayjs(res.data.memberEndTime).format(
                  'YYYY-MM-DD'
                )
              }
            } else {
              this.memberStartTime = this.dayjs().format('YYYY-MM-DD')
              this.memberEndTime = this.dayjs().format('YYYY-MM-DD')
            }
          }
        })
    },
    //客服组件赋值
    chooseCus(val) {
      this.cusValue = val
    },
    //律所组件
    chooseLaw(val) {
      this.lawfirmValue = val
    },
    //头部组件
    tabClick(val) {
      if (val === 0) {
        this.isLaw = true
        this.showMoney = true
        this.showChannel = true
        this.showImage = true
        this.showCus = true
        this.showApprove = true
        this.showRemark = true
        this.showJob = true
        this.titleType = '律师开通'
      } else {
        this.isLaw = false
        this.showMoney = false
        this.showChannel = false
        this.showImage = false
        this.showCus = false
        this.showApprove = false
        this.showRemark = false
        this.showJob = false
        this.titleType = '普通用户开通'
      }
    },
    //时间计算结果
    calcResult(val) {
      this.showMonth = val
    },
    //选择充值通道
    chooseChannel(val) {
      this.payChannel = val
    },
    //获取充值图片
    getUrl(val) {
      this.imageUrl = val
    },
    //选择审批人
    chooseApprove(val) {
      this.approveUser = val
    },
    //律所名称获取
    changeLawText(val) {
      this.lawfirmName = val
    },
    jobValue(val) {
      this.jobType = val
    },
    //申请vip
    apply() {
      //律师开通
      if (this.titleType === '律师开通') {
        if (!this.money) {
          this.$toast('请填写充值金额')
          return
        } else if (!this.showMonth) {
          this.$toast('请选择充值月数')
          return
        } else if (!this.payChannel) {
          this.$toast('请选择充值渠道')
          return
        } else if (this.lawfirmValue === 0) {
          this.$toast('请选择律所')
          return
        }
        this.$axios
          .post(`${this.$base}/admin/member/apply`, {
            account: this.account,
            applyUserId: this.userId,
            applyUserName: this.nickName,
            customerVest: this.cusValue,
            jobType: this.jobType,
            adminUserList: this.approveUser,
            lawFirmId: this.lawfirmValue,
            memberEndTime: this.showMonth,
            memberRemark: this.remark,
            memberStartTime: this.memberStartTime,
            nickname: this.nickName,
            rechargeChannel: this.payChannel,
            rechargeImgUrl: this.imageUrl,
            rechargeMoney: parseInt(this.money),
            rechargeMonths: parseInt(this.applyMonth),
            lawFirmName: this.lawfirmName,
            userId: this.userId,
            salesperson: this.salesperson,
          })
          .then((res) => {
            if (res.code === 200) {
              this.dis = true
              this.$toast('申请成功')
            } else {
              this.$toast(res.message)
            }
          })
        //普通开通
      } else {
        this.$axios
          .put(`${this.$base}/management/user/${this.userId}`, {
            memberEndTime: this.memberEndTime,
            nickName: this.nickName,
            phone: this.account,
            userId: this.userId,
          })
          .then((res) => {
            if (res.code === 200) {
              this.dis = true
              this.$toast('申请成功')
            }
          })
      }
    },
    //月份改变
    changeMonth(val) {
      this.applyMonth = val
    },
  },
  mounted() {
    this.loadSaler()
  },
}
</script>
<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
</style>
